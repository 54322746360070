.owl-carousel,
.owl-carousel .owl-item {
	-webkit-tap-highlight-color: transparent;
	position: relative;
}
.owl-carousel {
	display: none;
	width: 100%;
	z-index: 1;
}
.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-Y;
	-moz-backface-visibility: hidden;
}
.owl-carousel .owl-stage:after {
	content: '.';
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}
.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
	display: block;
	width: 100%;
}
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
	display: none;
}
.no-js .owl-carousel,
.owl-carousel.owl-loaded {
	display: block;
}
.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}
.owl-carousel.owl-hidden {
	opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
	visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}
.owl-carousel.owl-rtl {
	direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
	float: right;
}
.owl-carousel .animated {
	animation-duration: 1s;
	animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
	z-index: 0;
}
.owl-carousel .owl-animated-out {
	z-index: 1;
}
.owl-carousel .fadeOut {
	animation-name: fadeOut;
}
@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
.owl-height {
	transition: height 0.5s ease-in-out;
}
.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 0.4s ease;
}
.owl-carousel .owl-item img.owl-lazy {
	transform-style: preserve-3d;
}
.owl-carousel .owl-video-wrapper {
	position: relative;
	height: 100%;
	background: #000;
}
.owl-carousel .owl-video-play-icon {
	position: absolute;
	height: 80px;
	width: 80px;
	left: 50%;
	top: 50%;
	margin-left: -40px;
	margin-top: -40px;
	background: url(owl.video.play.png) no-repeat;
	cursor: pointer;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	transition: transform 0.1s ease;
}
.owl-carousel .owl-video-play-icon:hover {
	-ms-transform: scale(1.3, 1.3);
	transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
	display: none;
}
.owl-carousel .owl-video-tn {
	opacity: 0;
	height: 100%;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	transition: opacity 0.4s ease;
}
.owl-carousel .owl-video-frame {
	position: relative;
	z-index: 1;
	height: 100%;
	width: 100%;
}
.owl-theme .owl-dots,
.owl-theme .owl-nav {
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-nav {
	margin-top: 10px;
}
.owl-theme .owl-nav [class*='owl-'] {
	color: #fff;
	font-size: 14px;
	margin: 5px;
	padding: 4px 7px;
	background: #d6d6d6;
	display: inline-block;
	cursor: pointer;
	border-radius: 3px;
}
.owl-theme .owl-nav [class*='owl-']:hover {
	background: #869791;
	color: #fff;
	text-decoration: none;
}
.owl-theme .owl-nav .disabled {
	opacity: 0.5;
	cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
	margin-top: 10px;
}
.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
}
.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	background: #d6d6d6;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity 0.2s ease;
	border-radius: 30px;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #869791;
}


.owl-rmf-style {
    position: relative;
    overflow: hidden;

    .owl-nav {
        .owl-prev,
        .owl-next {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -30px;
            width: 60px;
            height: 60px;
            background: #fff;
            border-radius: 30px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 10px;
            box-sizing: border-box;

            &:before {
                display: block;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m8.25 4.5 7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
                width: 24px;
                height: 24px;
                background-size: 24px 24px;
            }
        }
    }

    .owl-prev {
        left: -30px;
        &:before {
            transform: rotate(180deg);
        }
    }

    .owl-next {
        justify-content: flex-start !important;
        right: -30px;

    }
}

@media (max-width: 992px) {
    .owl-rmf-style {
        position: relative;
        overflow: hidden;
    
        .owl-nav {
            .owl-prev,
            .owl-next {
                width: 40px;
                height: 40px;
                margin-top: -20px;
                padding: 4px;
            }
        }

        .owl-prev {
            left: -20px;
        }

        .owl-next {
            right: -20px;
        }
    }
}